<template>
  <div class="mobile-view">
    <img src="@/assets/icon/logo.png" alt="elu" style="width: 30vw">
    <div class="tc">
      <img src="@/assets/icon/slogn.png" alt="connect to the future" style="width: 56vw;margin-top: 16px">
    </div>
    <div class="content first" style="margin-top: 16px">
      <div class="title">
        关于我们
        <img class="icon" src="@/assets/icon/icon1.png">
      </div>
      <div class="text" id="box">
        <span class="mask">ELU是一家以技术驱动、科技引领的创新型人工智能科技企业，致力于将人工智能、机器人等前沿技术应用于绿色能源等领域，ELU深耕AI机器人和新能源电力两大万亿级交汇的行业赛道，以AI机器人作为新质生产力，配合全栈自研的超级能源引擎（HEE）驱动业务快速发展，加速推动城市智慧能源转型，助力实现国家“3060”战略目标。公司立志于通过践行“3+3+1”战略成为世界领先的AI机器人研发制造商和智慧能源运营商。</span>
        <div class="typed-wrap">
          <span id="typed" />
        </div>
      </div>
    </div>
    <div class="content-wrap" style="margin-top: 12px">
      <div class="content">
        <div class="title">
          机器人
          <img class="icon" src="@/assets/icon/icon2.png">
        </div>
        <div class="text">
          ELU致力于将前沿科技与能源行业深度融合，推出全球领先的智慧能源机器人。
        </div>
      </div>
    </div>
    <div class="content-wrap">
      <div class="content">
        <div class="title">
          人工智能
          <img class="icon" src="@/assets/icon/icon3.png">
        </div>
        <div class="text">
          ELU推出全栈自研的场景化AI引擎——超级能源引擎（HEE），提供定制化智能解决方案。
        </div>
      </div>
    </div>
    <div class="content-wrap">
      <div class="content">
        <div class="title">
          新能源
          <img class="icon" src="@/assets/icon/icon4.png">
        </div>
        <div class="text">
          ELU深耕新能源领域，提供从投建到管理、运营的全链路服务，为客户量身定制新能源场景化项目方案。
        </div>
      </div>
    </div>
    <div class="tip">ELU官网即将发布，<b>敬请期待</b>。</div>
  </div>
</template>
<script>
import Typed from 'typed.js'
export default {
  name: 'MobileView',
  data() {
    return {
      typeIns: null
    }
  },
  mounted() {
    this.initTyped()
  },
  methods: {
    initTyped() {
      if (this.typeIns) {
        this.typeIns.destroy()
      }
      this.typeIns = new Typed('#typed', {
        strings: ['ELU是一家以技术驱动^200、科技引领的创新型人工智能科技企业^500，致力于将人工智能^200、机器人^200等前沿技术应用于绿色能源等领域^500，ELU深耕AI机器人和新能源电力两大万亿级交汇的行业赛道^500，以AI机器人作为新质生产力^300，配合全栈自研的超级能源引擎（HEE）驱动业务快速发展^500，加速推动城市智慧能源转型^300，助力实现国家“3060”战略目标。'],
        typeSpeed: 64
      })
    }
  }
}
</script>
<style lang="less" scoped>
.mobile-view {
  min-height: 100vh;
  padding: 15px;
  padding-bottom: 24vw;
  background: #F5F8FF url('../assets/bg/m_all_bg.png') no-repeat scroll center top / 100%;
}
#box {
  position: relative;
  .mask {
    opacity: 0;
  }
  .typed-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}
.first {
  background: #FFF !important;
  .text {
    color: #8987A1;
  }
}
.content-wrap {
  background: #FFF !important;
  border-radius: 12px;
  margin-top: 32px;
}
.content {
  background: linear-gradient(90deg, #6668FF40, #6668FF10);
  border-radius: 12px;
  padding: 10px 20px;
  font-size: 15px;
  color: #000;
  .title {
    position: relative;
    font-weight: bold;
    line-height: 48px;
    .icon {
      height: 60px;
      position: absolute;
      right: -8px;
      top: -20px;
    }
  }
  .text {
    line-height: 24px;
    margin-bottom: 8px;
  }
}
.tip {
  position: fixed;
  height: 14vw;
  left: 15px;
  right: 15px;
  bottom: 15px;
  font-size: 4vw;
  line-height: 14vw;
  border-radius: 12px;
  background: #6668FF;
  color: #FFF;
  text-align: center;
}
</style>