import { render, staticRenderFns } from "./MobileView.vue?vue&type=template&id=465bcaaa&scoped=true"
import script from "./MobileView.vue?vue&type=script&lang=js"
export * from "./MobileView.vue?vue&type=script&lang=js"
import style0 from "./MobileView.vue?vue&type=style&index=0&id=465bcaaa&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "465bcaaa",
  null
  
)

export default component.exports